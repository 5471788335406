<template>
  <div></div>
</template>

<script>
import { Config } from '../utils/config'
import { Toast } from 'vant'

export default {
  name: 'Login',
  data () {
    return {}
  },
  mounted () {
    this.initialize()
  },
  methods: {
    // 初始化
    initialize () {
      Toast.loading({
        message: '正在登录中...',
        forbidClick: true,
        duration: 0
      })
      // 已登录则直接跳转到首页
      if (this.st.user.isLogin) {
        Toast.clear()
        this.$router.push({ name: 'Index' })
      }
      const code = this.getQueryString('code')
      if (code) {
        this.st.user.getToken(code)
          .then(res => {
            this.getUserInfo()
          }).catch(() => {
            this.st.user.auth()
          })
      } else {
        this.st.user.auth()
      }
    },

    // 获取用户信息
    getUserInfo () {
      this.st.user.getUserInfo()
        .then(res => {
          if (res) {
            Toast.clear()
            this.$store.dispatch('setUserInfo', res)
            this.getUserRoles(res.mobile)
          } else {
            Toast('获取不到用户信息')
          }
        })
        .catch(() => {})
    },
    // 获取用户角色
    getUserRoles (mobile) {
      this.$api.users.roles({
        clientID: Config.clientID,
        mobile: mobile
      }).then(res => {
        if (res.status === 200 && res.data.length > 0) {
          this.$store.dispatch('setUserRoles', res.data[0])
          const url = window.location.href.split('?')
          window.location.href = `${url[0]}#/index`
        } else {
          Toast({
            message: '您没有相关权限，请使用其它手机号',
            duration: 2400
          })
          setTimeout(() => {
            localStorage.clear()
            this.st.user.logout()
            this.st.user.auth()
          }, 2400)
        }
      })
    },

    getQueryString (name) {
      const reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)')
      const r = window.location.search.substr(1).match(reg)
      if (r != null) return unescape(r[2]); return null
    }
  }
}
</script>
